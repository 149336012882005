export const REDUCER_KEY = 'i18n';
export const SET_I18N = 'SET_I18N';
export const I18N_KEY = 'i18n';

export const DEFAULT_LOCALE = 'en-US';

/**
 * @type  {Object<string,string>}
 */
export const LANGUAGE_FALLBACK = {
  // TWO-LETTER LANGUAGE CODES (alphabetical order)
  ar: 'ar-AE',
  az: 'az-AZ',
  bg: 'bg-BG',
  bn: 'bn-IN',
  bs: 'bs-BA',
  ca: 'ca-ES',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-GB',
  es: 'es-ES',
  fa: 'fa-IR',
  fi: 'fi-FI',
  fr: 'fr-FR',
  ga: 'en-IE', // use English site for Ireland
  gu: 'gu-IN',
  hi: 'hi-IN',
  hr: 'hr-HR',
  hu: 'hu-HU',
  id: 'id-ID',
  is: 'is-IS',
  it: 'it-IT',
  iw: 'iw-IL',
  ja: 'ja-JP',
  jv: 'jv-ID',
  ka: 'ka-GE',
  kk: 'kk-KZ',
  kn: 'kn-IN',
  ko: 'ko-KR',
  lt: 'lt-LT',
  lv: 'lv-LV',
  mk: 'mk-MK',
  mn: 'mn-MN',
  ms: 'ms-MY',
  mt: 'en-MT', // use English site for Malta
  nl: 'nl-NL',
  no: 'no-NO',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ro: 'ro-RO',
  ru: 'ru-RU',
  si: 'si-LK',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sq: 'sq-AL',
  sr: 'sr-RS',
  sv: 'sv-SE',
  sw: 'sw-KE',
  ta: 'ta-IN',
  te: 'te-IN',
  tg: 'tg-TJ',
  th: 'th-TH',
  tk: 'tk-TM',
  tl: 'tl-PH',
  tr: 'tr-TR',
  uk: 'uk-UA',
  ur: 'ur-PK',
  uz: 'uz-UZ',
  vi: 'vi-VN',
  zh: 'zh-CN',
  // THREE-LETTER LANGUAGE CODES (alphabetical order)
  kam: 'en-GB', // Kikambe, Kenya
  kea: 'en-GB', // Kabuverdianu-Kabu, Cape Verdi
  kok: 'en-IN', // Konkani, India
};
