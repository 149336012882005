import { createSelector } from 'reselect';
import {
  subscriptionsEnabledSelector,
  hasUpsClassicCookie,
} from '@wxu/contexts/src/subscription/selectors';
import {
  countryCodeSelector,
  languageCodeSelector,
} from '@wxu/contexts/src/i18n/selectors/locale';
import {
  experienceQuerySelector,
} from '@wxu/contexts/src/page/selectors';
import { geoIPCountryCodeSelector } from '@wxu/contexts/src/user-location/selectors';
import {
  REGISTRATION_EXCLUSION_LIST,
  VALID_APPS_REGISTRATION_LANGUAGES,
} from './constants';

export const registrationEnabledSelector = createSelector(
  languageCodeSelector,
  countryCodeSelector,
  geoIPCountryCodeSelector,
  subscriptionsEnabledSelector,
  experienceQuerySelector,
  (
    language,
    localeCountryCode,
    geoIPCountryCode,
    subscriptionsEnabled,
    experience
  ) => {
    if (subscriptionsEnabled || experience === 'wu') return true;

    if (['ios', 'android'].includes(experience)) {
      if (VALID_APPS_REGISTRATION_LANGUAGES.includes(language)
        && !hasUpsClassicCookie()
      ) return true;

      return false;
    }

    if (
      REGISTRATION_EXCLUSION_LIST.includes(localeCountryCode)
      || REGISTRATION_EXCLUSION_LIST.includes(geoIPCountryCode)
    ) return false;

    return true;
  }
);
