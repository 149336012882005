import { createSelector } from 'reselect';
import { localeSelector } from '@wxu/contexts/src/i18n/selectors';
import { geoIPCountryCodeSelector } from '@wxu/contexts/src/user-location/selectors';
import { isAppPageConfigSelector, isExperienceWuWebSelector } from '@wxu/page-config/src/selectors';
import { isWuAppIdSelector } from '@wxu/contexts/src/page/selectors';
import { NEWSLETTER_ENABLED_LINKS } from './constants';

export const newsletterEnabledSelector = createSelector(
  geoIPCountryCodeSelector,
  localeSelector,
  isAppPageConfigSelector,
  isExperienceWuWebSelector,
  isWuAppIdSelector,
  (
    geoIPCountryCode,
    locale,
    isApp,
    isWuWeb,
    isWuAppId,
  ) => (
    // Apps criteria
    (isApp && locale === 'en-US' && !isWuAppId)
    // Web criteria
    || (!isApp && geoIPCountryCode === 'US' && locale === 'en-US' && !isWuWeb)
  )
);

export const newsletterEnabledServerSelector = createSelector(
  newsletterEnabledSelector,
  localeSelector,
  isAppPageConfigSelector,
  isExperienceWuWebSelector,
  (
    newsletterEnabled,
    locale,
    isApp,
    isWuWeb,
  ) => (
    // Apps criteria
    (newsletterEnabled)
    // Web criteria
    || (!isApp && locale === 'en-US' && !isWuWeb)
  )
);

export function validateNewsletterEnabledLink(newsletterEnabled, linkObject, partner) {
  const isNewsletterEnabledLink = (
    NEWSLETTER_ENABLED_LINKS.includes(linkObject.title)
     || NEWSLETTER_ENABLED_LINKS.includes(linkObject.urlTranslationKey)
     || NEWSLETTER_ENABLED_LINKS.includes(linkObject.titleTranslationKey)
  );
  const isSamsung = partner?.includes?.('samsung');

  if (isNewsletterEnabledLink && (!newsletterEnabled || isSamsung)) return false;

  return true;
}
