export const VALID_APPS_REGISTRATION_LANGUAGES = [
  'es',
  'en',
];
export const REGISTRATION_EXCLUSION_LIST = [
  'CU', // Cuba
  'CN', // China
  'IR', // Iran
  'BY', // Belarus
  'KP', // North Korea
  'KR', // South Korea
  'RU', // Russia
  'SD', // Sudan
  'SS', // South Sudan
  'SY', // Syria
  'UA', // Ukraine
  'VE', // Venezuela
  'VN', // Vietnam
];
export const REGISTRATION_ENABLED_LINKS = [
  'account',
  'manageAccount',
  'memberSignup',
  'memberLogin',
  'memberSettings',
  'myDashboard',
];
