export const SOCIAL_LINKS = [
  {
    title: 'Facebook',
    titleTranslationKey: 'facebook',
    url: 'https://www.facebook.com/TheWeatherChannel',
    target: '_blank',
    rel: 'noopener noreferrer nofollow',
    icon: {
      set: 'footer',
      name: 'facebook',
    },
  },
  {
    title: 'X',
    titleTranslationKey: 'X',
    url: 'http://www.twitter.com/weatherchannel',
    target: '_blank',
    rel: 'noopener noreferrer nofollow',
    icon: {
      set: 'footer',
      name: 'xLogo',
    },
  },
  {
    title: 'Instagram',
    titleTranslationKey: 'instagram',
    url: 'http://instagram.com/weatherchannel',
    target: '_blank',
    rel: 'noopener noreferrer nofollow',
    icon: {
      set: 'footer',
      name: 'instagram',
    },
  },
  {
    title: 'Youtube',
    titleTranslationKey: 'youtube',
    url: 'http://www.youtube.com/user/TheWeatherChannel/',
    target: '_blank',
    rel: 'noopener noreferrer nofollow',
    icon: {
      set: 'footer',
      name: 'youtube',
    },
  },
];
