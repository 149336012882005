const TERMS_OF_USE_KEY = 'termsOfUse';

export const TERMS_OF_USE_LINKS = [
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/${locale}/legal',
    wuUrl: '//www.wunderground.com/company/legal',
    excludeLocales: ['de-DE', 'en-GB', 'en-IN', 'ca-ES', 'cs-CZ', 'es-ES', 'fr-FR', 'hr-HR', 'hu-HU', 'it-IT', 'nl-NL', 'pl-PL', 'pt-BR', 'pt-PT', 'ro-RO', 'sk-SK', 'da-DK'],
    includeEnUsLocale: false,
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/de-DE/agb',
    includeLocales: ['de-DE'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/ca-ES/termes',
    includeLocales: ['ca-ES'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/cs-CZ/smluvnipodminky',
    includeLocales: ['cs-CZ'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/es-ES/terminos',
    includeLocales: ['es-ES'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/fr-FR/conditions',
    includeLocales: ['fr-FR'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/hr-HR/uvjetiiodredbe',
    includeLocales: ['hr-HR'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/hu-HU/altalanosfeltetelek',
    includeLocales: ['hu-HU'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/it-IT/termini',
    includeLocales: ['it-IT'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/nl-NL/voorwaarden',
    includeLocales: ['nl-NL'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/pl-PL/zastrzezenia',
    includeLocales: ['pl-PL'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/${locale}/termos',
    includeLocales: ['pt-BR', 'pt-PT'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/ro-RO/termeni',
    includeLocales: ['ro-RO'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/sk-SK/podmienky',
    includeLocales: ['sk-SK'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/da-DK/vilkarbetingelser',
    includeLocales: ['da-DK'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: TERMS_OF_USE_KEY,
    url: '/${locale}/terms',
    includeLocales: ['en-IN', 'en-GB'],
    target: '_self',
    rel: 'nofollow',
  },
];

export const PRIVACY_POLICY_LINKS = [
  {
    titleTranslationKey: 'privacyPolicy',
    url: '/en-US/twc/privacy-policy',
    wuUrl: '//www.wunderground.com/company/privacy-policy',
    includeLocales: ['en-US'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: 'privacyPolicy',
    url: '/de-DE/datenschutz',
    includeLocales: ['de-DE'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: 'privacyPolicy',
    url: '/${locale}/intl-privacy',
    excludeLocales: ['de-DE', 'en-US'],
    target: '_self',
    rel: 'nofollow',
  },
];

export const COOKIE_NOTICE_LINK = {
  titleTranslationKey: 'cookieNotice',
  url: '/en-US/twc/privacy-policy#us-data-coll-tech-current',
  includeLocales: ['en-US'],
  target: '_blank',
  rel: 'nofollow',
  newWindow: true,
};

export const CALIFORNIA_NOTICE_LINK = {
  titleTranslationKey: 'californiaNotice',
  url: '/en-US/twc/privacy-policy#us-ccpa-notice-current',
  includeLocales: ['en-US'],
  target: '_blank',
  rel: 'nofollow',
  newWindow: true,
};

export const ADCHOICES_LINK = {
  titleTranslationKey: 'adChoices',
  url: '/${locale}/services/ad-choices',
  wuUrl: '//www.wunderground.com/company/ad-choices',
  includeLocales: ['en-US', 'es-US', 'en-CA', 'fr-CA'],
  includeEnUsLocale: false,
  target: '_self',
  rel: 'nofollow',
  inlineIcon: {
    set: 'footer',
    name: 'adChoices',
  },
};

export const DATA_VENDORS_LINK = {
  titleTranslationKey: 'dataVendors',
  url: '/${locale}/data-vendors',
  wuUrl: '//www.wunderground.com/data-vendors',
  includeEnUsLocale: false,
  target: '_self',
  rel: 'nofollow',
};

export const ESSENTIAL_LINK = {
  title: 'eSSENTIAL',
  url: 'https://www.essentialaccessibility.com/the-weather-channel?utm_source=theweatherchannelhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=theweatherchannel',
  target: '_self',
  icon: {
    set: 'footer',
    name: 'eSSENTIAL',
    ariaLabel: 'Link to eSSENTIAL Accessibility assistive technology',
  },
};

export const ACCESSIBILITY_STATEMENT_LINK = {
  titleTranslationKey: 'accessibilityStatement',
  url: '/${locale}/accessibility-statement',
  wuUrl: '//www.wunderground.com/accessibility-statement',
  includeEnUsLocale: false,
  target: '_self',
  rel: 'nofollow',
};

export const LEGAL_LINKS = [
  ...TERMS_OF_USE_LINKS,
  ...PRIVACY_POLICY_LINKS,
  COOKIE_NOTICE_LINK,
  ADCHOICES_LINK,
  ACCESSIBILITY_STATEMENT_LINK,
  DATA_VENDORS_LINK,
];

