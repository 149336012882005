/**
 * An additional property 'noShow' can be added to a link.
 *  This property is an array of deviceClasses for which
 *  the link should not appear. newsLettersAlerts has
 *  that property.
 */
export const CORPORATE_LINKS = [
  {
    titleTranslationKey: 'feedback',
    url: '//support.weather.com/s/',
    queryString: 'language=${lang}&type=TWC',
    wuQueryString: 'language=${lang}&type=WU',
    target: '_blank',
    rel: 'noopener nofollow',
    customLanguage: {
      'zh-CN': 'zh_CN',
      'nl-NL': 'nl_NL',
      'en-US': 'en_US',
      'en-GB': 'en_GB',
      'pt-BR': 'pt_BR',
      'pt-PT': 'pt_PT',
      'es-US': 'es_US',
    },
  },
  {
    titleTranslationKey: 'weatherAPI',
    url: 'https://www.wunderground.com/weather/api/?ref=twc-DE',
    includeLocales: ['de-DE'],
    excludePartners: ['samsungVietnam'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: 'weatherAPI',
    url: 'https://www.wunderground.com/weather/api',
    excludeLocales: ['de-DE', 'en-US'],
    excludePartners: ['samsungVietnam'],
    target: '_self',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: 'mission',
    url: '/mission',
    includeLocales: ['en-US'],
    target: '_blank',
    rel: 'noopener nofollow',
  },
  {
    titleTranslationKey: 'careers',
    url: 'https://www.weathercompany.com/careers',
    includeLocales: ['en-US'],
    target: '_blank',
    rel: 'noopener nofollow',
  },
  // WEB-19460 removing for legal reasons
  // {
  //   titleTranslationKey: 'downloadApps',
  //   url: '/apps',
  //   includeLocales: ['en-US'],
  //   target: '_self',
  //   rel: 'noopener nofollow',
  // },
  {
    titleTranslationKey: 'pressRoom',
    url: 'https://www.weathercompany.com/newsroom',
    target: '_blank',
    rel: 'noopener nofollow',
  },
  {
    titleTranslationKey: 'advertiseWithUs',
    url: 'https://www.weathercompany.com/advertising/',
    includeLocales: ['en-US', 'de-DE'],
    target: '_blank',
    rel: 'noopener nofollow',
  },
  {
    titleTranslationKey: 'tv',
    url: 'https://www.weathergroup.com/brands/the-weather-channel/',
    includeLocales: ['en-US'],
    target: '_blank',
    rel: 'nofollow',
  },
  {
    titleTranslationKey: 'newsletterSignUp',
    url: 'https://weather.com/newsletter?cm_ven=dnt_newsletter_footer',
    includeLocales: ['en-US'],
    target: '_self',
    rel: 'nofollow',
  },
  /*
    TODO: determine if this can be used for non-Samsung pages
    Disabling this upsell content temporarily, as a part of:
    https://jira.weather.com:8443/browse/WEB-21985
  */
  // {
  //   titleTranslationKey: 'newslettersAlerts',
  //   url: '/${locale}/life/manage-notifications',
  //   target: '_self',
  //   rel: 'nofollow',
  //   noShow: ['desktop'],
  // },
  {
    titleTranslationKey: 'impressum',
    url: '//weather.com/de-DE/impressum',
    includeLocales: ['de-DE'],
    target: '_blank',
    rel: 'noopener nofollow',
  },
  {
    titleTranslationKey: 'weatherDataAPIs',
    url: 'https://www.weathercompany.com/weather-data-apis/',
    target: '_blank',
    rel: 'noopener nofollow',
  },
];
