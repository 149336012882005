export const CA = 'ca';
export const CA_QC = 'ca-qc';
export const EXEMPT = 'exempt';
export const GDPR = 'gdpr';
export const JP = 'jp';
export const KR = 'kr';
export const LATAM_CO = 'latam-co';
export const LATAM_DO = 'latam-do';
export const LATAM_PE = 'latam-pe';
export const LGPD = 'lgpd';
export const PIPL = 'pipl';
export const TR_KVKK = 'tr-kvkk';
export const USA = 'usa';
export const USA_CCPA = 'usa-ccpa';
export const USA_CO = 'usa-co';
export const USA_CT = 'usa-ct';
export const USA_VA = 'usa-va';

export type Regime =
  typeof CA |
  typeof CA_QC |
  typeof EXEMPT |
  typeof GDPR |
  typeof JP |
  typeof KR |
  typeof LATAM_CO |
  typeof LATAM_DO |
  typeof LATAM_PE |
  typeof LGPD |
  typeof PIPL |
  typeof TR_KVKK |
  typeof USA |
  typeof USA_CCPA |
  typeof USA_CO |
  typeof USA_CT |
  typeof USA_VA;

export const RESTRICTIVE_REGIMES: Regime[] = [
  CA_QC,
  GDPR,
  LATAM_CO,
  LATAM_DO,
  LATAM_PE,
  LGPD,
  PIPL,
  TR_KVKK,
];

// FOR WEB CANADA regimes
export const CANADA_REGIMES: Regime[] = [
  CA,
  CA_QC,
];

// For WEB US regimes
export const US_REGIMES: Regime[] = [
  USA,
  USA_CCPA,
  USA_CO,
  USA_CT,
  USA_VA,
];

/**
 *
 * For exclusive Apps US regimes.
 * Checks for usa- prefix to support future apps usa-xx regimes.
 *
 */
export const isUSRegime = (regime) => regime === USA || regime?.includes?.('usa-');
