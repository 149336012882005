import { getEnv } from '@wxu/env';

// redux
export const SUBSCRIPTION_TIER_STANDARD = 'standard';
export const SUBSCRIPTION_TIER_NONE = 'none';
export const SUBSCRIPTION_TIER0 = 'tier0';
export const SUBSCRIPTION_TIER1 = 'tier1';
export const SUBSCRIPTION_TIER2 = 'ad-free-v2';
export const SUBSCRIPTION_TIER3 = 'tier3';
export const SUBSCRIPTION_PRODUCT_ALL = 'all';
export const SUBSCRIPTION_PRODUCT_ADFREE = 'adFree';
export const SUBSCRIPTION_PRODUCT_RADAR = 'radar';
export const SUBSCRIPTION_PRODUCT_HOURLY = 'hourly';
export const SUBSCRIPTION_PRODUCT_15MINHOURLY = 'fifteenMinHourly';
export const WU_SUBSCRIPTION_LEGACY_AD_FREE = 'legacy-ad-free';
export const WU_SUBSCRIPTION_AD_FREE = 'wu-ad-free';
export const OFFER_CHECKOUT = 'OFFER_CHECKOUT';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_MESSAGE_MODAL = 'SET_MESSAGE_MODAL';
export const SET_IS_CONTINUOUS_ORDER = 'SET_IS_CONTINUOUS_ORDER';
export const IS_CONTINUOUS_ORDER = 'isContinuousOrder';

export const PREMIUM_SUBSCRIPTION_TIERS = [
  SUBSCRIPTION_TIER0,
  SUBSCRIPTION_TIER1,
  SUBSCRIPTION_TIER2,
  SUBSCRIPTION_TIER3,
];

export const WU_PREMIUM_SUBSCRIPTION_TIERS = [
  WU_SUBSCRIPTION_LEGACY_AD_FREE,
  WU_SUBSCRIPTION_AD_FREE,
];

// tier by feature support
export const TIERS_BY_FEATURE = {
  [SUBSCRIPTION_TIER0]: {
    include: [SUBSCRIPTION_PRODUCT_ALL],
    exclude: [],
  },
  [SUBSCRIPTION_TIER1]: {
    include: [SUBSCRIPTION_PRODUCT_ALL],
    exclude: [],
  },
  [SUBSCRIPTION_TIER2]: {
    include: [SUBSCRIPTION_PRODUCT_ADFREE],
    exclude: [],
  },
  [SUBSCRIPTION_TIER3]: {
    include: [SUBSCRIPTION_PRODUCT_ALL],
    exclude: [SUBSCRIPTION_PRODUCT_ADFREE],
  },
  [SUBSCRIPTION_TIER_STANDARD]: {
    include: [],
    exclude: [SUBSCRIPTION_PRODUCT_ADFREE],
  },
};

export const VALID_SUBSCRIPTION_LOCALES = [
  'en-US',
];
export const VALID_SUBSCRIPTION_TIERS = [
  ...PREMIUM_SUBSCRIPTION_TIERS,
  SUBSCRIPTION_TIER_STANDARD,
];
export const WU_VALID_SUBSCRIPTION_TIERS = [
  ...WU_PREMIUM_SUBSCRIPTION_TIERS,
  SUBSCRIPTION_TIER_STANDARD,
];
export const RESTRICTED_PAGES = [
  'memberSettings',
];
export const SUBSCRIPTION_ENABLED_LINKS = [
  'goPremium',
  'manageAccount',
];

export const SUBS_DISABLED_PARTNERS = [
];

export const BUNDLING_PARTNERS_MAPPING_URL_CONFIG = {
  name: 'getMewEntityUrlConfig',
  params: {
    entityType: 'state_config',
    entityId: getEnv('BUNDLING_PARTNERS_MAPPING_ID'),
  },
};

export const BUNDLING_PARTNER_TERMS_MAPPING_URL_CONFIG = {
  name: 'getMewEntityUrlConfig',
  params: {
    entityType: 'state_config',
    entityId: getEnv('BUNDLING_PARTNER_TERMS_MAPPING_ID'),
  },
};

export const getProductData = (productId, terms, partners) => {
  const result = terms?.[productId] || terms?.default;

  if (!result) return {};

  const partner = partners?.[result.partner] || {};

  return { ...result, ...partner };
};

export const BRAZE_REGISTER_FAIL_VALUE = 'brazeSignUpData';

export const CHECKOUT_IS_CONTINUOUS_ORDER = 'CHECKOUT_IS_CONTINUOUS_ORDER';
