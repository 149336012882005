import get from 'lodash/get';
import { createSelector } from 'reselect';
import Cookies from 'cookies-js';
import { env } from '@wxu/env';
import { getDalDataSelector } from '@wxu/contexts/src/redux-dal/selectors';
import {
  localeSelector,
} from '@wxu/contexts/src/i18n/selectors/locale';
import {
  killswitchSelector,
  pageSubscriptionTierSelector,
  experienceQuerySelector,
  pageKeySelector,
  privacyHeaderSelector,
  partnerHeaderSelector,
} from '@wxu/contexts/src/page/selectors';
import { geoIPCountryCodeSelector } from '@wxu/contexts/src/user-location/selectors';
import { isTierFeatureIncluded } from '@wxu/utilities/src/checkTierFeatures';
import { isUSRegime } from '@wxu/dpr-sdk/src/configs/regimesConfig';
import {
  PREMIUM_SUBSCRIPTION_TIERS,
  VALID_SUBSCRIPTION_LOCALES,
  SUBSCRIPTION_TIER_NONE,
  VALID_SUBSCRIPTION_TIERS,
  SUBSCRIPTION_PRODUCT_ADFREE,
  RESTRICTED_PAGES,
  BUNDLING_PARTNER_TERMS_MAPPING_URL_CONFIG,
  TIERS_BY_FEATURE,
  IS_CONTINUOUS_ORDER,
  WU_PREMIUM_SUBSCRIPTION_TIERS,
  WU_VALID_SUBSCRIPTION_TIERS,
  SUBS_DISABLED_PARTNERS,
  SUBSCRIPTION_ENABLED_LINKS,
} from './constants';
import { REDUCER_KEY } from './reducer';

export const openModalSelector = state => state[REDUCER_KEY]?.openModal || false;
export const messageModalSelector = state => state[REDUCER_KEY]?.messageModal || false;
export const isContinuousOrderSelector = state => state[REDUCER_KEY]?.[IS_CONTINUOUS_ORDER] || null;

export const isInsideGeofenceSelector = createSelector(
  geoIPCountryCodeSelector,
  (geoIpCountryCode) => {
    if (env.WEBCAKES_ENV === 'PROD') return geoIpCountryCode === 'US';
    const { GEOFENCE_OVERRIDE_CLIENT } = env;

    const shouldOverride = __CLIENT__ && GEOFENCE_OVERRIDE_CLIENT && (Cookies.get('geofenceOverride') === GEOFENCE_OVERRIDE_CLIENT);

    return shouldOverride || geoIpCountryCode === 'US';
  }
);

export const hasUpsClassicCookie = () => {
  const hasDsxCookie = __CLIENT__ && Cookies.get('dsx');

  if (hasDsxCookie) return true;

  return false;
};

export const subscriptionsEnabledSelector = createSelector(
  localeSelector,
  partnerHeaderSelector,
  isInsideGeofenceSelector,
  privacyHeaderSelector,
  experienceQuerySelector,
  (locale, partner, insideGeofence, regime, experience) => {
    if (['ios', 'android'].includes(experience)) {
      if (locale === 'en-US' && insideGeofence && !hasUpsClassicCookie()) return true;
      return false;
    }

    if (experience === 'wu') return true;
    const validLocale = !locale || (VALID_SUBSCRIPTION_LOCALES.indexOf(locale) > -1);
    let validPartner = true;

    SUBS_DISABLED_PARTNERS.forEach(par => {
      if (partner.includes(par)) validPartner = false;
    });

    const subscriptionsEnabled = validLocale && isUSRegime(regime) && validPartner;

    // Check will not work locally unless CI Cookie is modified, or IP header is set
    if (__CLIENT__ && !insideGeofence) {
      return false;
    }

    return subscriptionsEnabled;
  }
);

export const isValidSubscriptionTier = (tier) => VALID_SUBSCRIPTION_TIERS.indexOf(tier) > -1;

export const isWuValidSubscriptionTier = (tier) => WU_VALID_SUBSCRIPTION_TIERS.indexOf(tier) > -1;

export const isPremiumSubscriptionTier = (tier) => PREMIUM_SUBSCRIPTION_TIERS.indexOf(tier) > -1;

export const isWuPremiumSubscriptionTier = (tier) => WU_PREMIUM_SUBSCRIPTION_TIERS.indexOf(tier) > -1;

export const isAdFreeSubscriptionTier = (tier) => PREMIUM_SUBSCRIPTION_TIERS.indexOf(tier) === 2;

export const entitlementFilter = (tier) => TIERS_BY_FEATURE[tier];

export const subscriptionProductPlatform = (product = '') => {
  const [_platform, _tier] = product.split('-'); // fmt: {platform}-{tier} || {tier}
  const platform = (_tier) ? _platform : null;

  return platform;
};

export const subscriptionProductTier = (product = '') => {
  const [, _tier] = product.split('-'); // fmt: {platform}-{tier} || {tier}
  let tier;

  if (product.startsWith('web') || product.startsWith('app')) {
    tier = _tier || product;
  } else {
    tier = product;
  }
  return tier;
};

export const subscriptionTierSelector = createSelector(
  pageSubscriptionTierSelector,
  (pageSubscriptionTier) => {
    const _subscriptionTier = pageSubscriptionTier || SUBSCRIPTION_TIER_NONE;
    const subscriptionTier = subscriptionProductTier(_subscriptionTier);

    return subscriptionTier;
  }
);

export const getIsProductAllowedForSubscriptionTierSelector = (product) => createSelector(
  subscriptionsEnabledSelector,
  subscriptionTierSelector,
  (subscriptionsEnabled, tier) => {
    if (!subscriptionsEnabled) return false;

    const allowedProducts = get(
      TIERS_BY_FEATURE,
      [tier],
    );
    const allow = isTierFeatureIncluded(allowedProducts, product);

    return allow;
  }
);

export const noAdsSelector = createSelector(
  killswitchSelector,
  subscriptionsEnabledSelector,
  getIsProductAllowedForSubscriptionTierSelector(
    SUBSCRIPTION_PRODUCT_ADFREE
  ),
  (twcKillswitch, subscriptionsEnabled, allowAdFree) => {
    const killswitches = (twcKillswitch && twcKillswitch.split(',')) || [];
    const hardStop = killswitches.includes(SUBSCRIPTION_PRODUCT_ADFREE)
      || killswitches.includes('allthethings') || killswitches.includes('ads');
    const noAds = hardStop || (subscriptionsEnabled && allowAdFree);

    return noAds;
  }
);

export const doesCurrentPageRequireAuthenticationSelector = createSelector(
  pageKeySelector,
  (pageKey) => RESTRICTED_PAGES.indexOf(pageKey) > -1
);

export const bundlingPartnerTermsMappingSelector = createSelector(
  getDalDataSelector,
  (getDalData) => {
    const data = getDalData(BUNDLING_PARTNER_TERMS_MAPPING_URL_CONFIG);

    return data?.data?.attributes?.value;
  }
);

export function validateSubscriptionsEnabledLink(subscriptionsEnabled, linkObject) {
  const isSubscriptionsEnabledLink = (
    SUBSCRIPTION_ENABLED_LINKS.includes(linkObject.title)
     || SUBSCRIPTION_ENABLED_LINKS.includes(linkObject.urlTranslationKey)
     || SUBSCRIPTION_ENABLED_LINKS.includes(linkObject.titleTranslationKey)
  );

  if (isSubscriptionsEnabledLink && !subscriptionsEnabled) return false;

  return true;
}
